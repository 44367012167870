import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import fileService from "../../services/file.service";
import fileUploadService from "../../services/file.service";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../utilities/Loader2";
import ButtonNeoGen from "../../layout/button-neogen";
import usersService from "../../services/users.service";
import Swal from "sweetalert2";
import _ from "lodash";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router";
import authService from "../../services/auth.service";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import Filter from "../../layout/filter";
const MySwal = withReactContent(Swal);

interface FilesProps {
    companyId?: number;
}

function compare(b: any, a: any) {
    if (a.dateUploaded < b.dateUploaded) {
        return -1;
    }
    if (a.dateUploaded > b.dateUploaded) {
        return 1;
    }
    return 0;
}

function compareUsers(a: ClearERCUser, b: ClearERCUser) {
    a.name = a.name ?? a.email ?? "-- No Name or Email --";
    b.name = b.name ?? b.email ?? "-- No Name or Email --";
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function MyFiles(props?: FilesProps) {
    const [showAddFile, setShowAddFile] = useState(false);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [users, setUsers] = useState<ClearERCUser[]>([]);
    const [allFiles, setAllFiles] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const queryCache = useQueryClient();
    const { id } = useParams();
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (id) {
            setSelectedUser(id);
        }
    }, [id]);

    function downloadZip() {
        MySwal.fire({
            title: "Creating Zip File",
            html: (
                <>
                    Please wait...
                    <Loader2 />
                </>
            ),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false,
            didOpen: async () => {
                console.log({ files: filesQuery.data });
                const filenames = filesQuery.data?.map((file) => file.filename);
                if (filenames) {
                    fileService.createZip(filenames).then(async (r) => {
                        if (r) {
                            console.error(r.data.filename);
                            let filesize = 0;
                            while (!filesize) {
                                console.log("Checking");
                                const file = await fileService.getOne(r.data.id);
                                if (file) {
                                    filesize = file.data.size;
                                }
                                await new Promise((r) => setTimeout(r, 1000));
                            }
                            fileService.downloadFile(r.data.filename, r.data.originalFilename);
                            MySwal.close();
                        }
                    });
                }

                console.log("Download All");

                return false;
            },
        })
            .then(() => {
                Swal.fire({
                    title: "Zip Created",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                // }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const queryKey = allFiles ? ["allFiles", props?.companyId] : ["files", props?.companyId];
    const filesQuery = useQuery(
        queryKey,
        async () => {
            let response;
            if (allFiles) {
                response = await fileUploadService.getAll({
                    where: { companyId: props?.companyId },
                });
            } else {
                response = await fileUploadService.getMyFiles();
            }
            if (response) {
                const tempUsers: ClearERCUser[] = [];
                if (allFiles) {
                    const users = await usersService.getAll();
                    if (users) {
                        users.data.forEach((user) => {
                            if (!tempUsers.find((u) => u.id === user.id)) {
                                tempUsers.push(user);
                            }
                        });
                    }
                } else {
                    response.data.forEach((upload) => {
                        if (!tempUsers.find((user) => user.id === upload.uploaded_by)) {
                            const uploadUser = usersQuery.data?.find((user) => user.id === upload.uploaded_by);
                            if (uploadUser) {
                                tempUsers.push(uploadUser);
                            }
                        }
                    });
                }

                setUsers(
                    tempUsers
                        .map((a) => {
                            if (a.name === "") {
                                a.name = a.email;
                            }
                            a.name = a.name ?? a.email ?? "-- No Name or Email --";
                            a.name += ` (${a.email})`;
                            return a;
                        })
                        .sort(compareUsers),
                );
                return response.data.sort(compare);
            }
        },
        { enabled: usersQuery.isSuccess },
    );

    useEffect(() => {
        authService.canIAccess("SEE_ALL_FILES").then((r) => {
            if (r) {
                setAllFiles(true);
            }
        });
    }, []);

    return (
        <>
            <PageDescription title="Files" description="All the files you are able to access in the system." />
            {filesQuery.isLoading || usersQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <div className="rounded-xl bg-slate-50 p-5 shadow border-t-2 border-l-2 border-white dark:bg-gradient-to-br dark:from-gray-900 dark:to-gray-800 dark:border-2 dark:border-gray-700">
                        <div className="flex flex-col sm:flex-row gap-5">
                            <div className="flex-grow">
                                <Filter
                                    label="Uploaded By"
                                    hideReset={false}
                                    options={users}
                                    value={selectedUser ?? ""}
                                    onChange={(value) => {
                                        setSelectedUser(value.toString());
                                        filesQuery.refetch();
                                    }}
                                />
                            </div>
                            <div className="flex-grow self-end">
                                <ButtonNeoGen
                                    onClick={() => {
                                        downloadZip();
                                    }}
                                    type="secondary"
                                    text={downloading ? "Downloading" : "Download All as Zip"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="mx-2">
                            <TableNeogen
                                entries={
                                    ((selectedUser ?? "").length > 0
                                        ? filesQuery.data?.filter((file) => file.uploaded_by === selectedUser)
                                        : filesQuery.data) ?? []
                                }
                                headers={["Uploaded", "Filename", "Size", "Uploaded By", "Download", "Delete"]}
                                formatters={[
                                    {
                                        field: "dateUploaded",
                                        type: "DateTime",
                                    },
                                    {
                                        field: "size",
                                        type: "FileSize",
                                    },
                                    {
                                        field: "uploadedBy",
                                        type: "User",
                                    },
                                ]}
                                ignoreFields={[
                                    "encoding",
                                    "mimetype",
                                    "isArchived",
                                    "notes",
                                    "filename",
                                    "documentTypeId",
                                    "lastUpdated",
                                    "key",
                                ]}
                                actions={[
                                    {
                                        label: "Download",
                                        icon: "fas fa-download",
                                        onClick: (entry) => {
                                            const file = filesQuery.data?.find((file) => file.id === entry);
                                            if (file) {
                                                fileService.downloadFile(file.filename, file.originalFilename);
                                            }
                                        },
                                    },
                                    {
                                        label: "Delete",
                                        icon: "fas fa-trash",
                                        className: "bg-red-500 text-white",

                                        onClick: (entry) => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                            }).then((result) => {
                                                if (result.value) {
                                                    fileUploadService.deleteByID(entry).then(() => {
                                                        queryCache.invalidateQueries(["files"]);
                                                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                                                    });
                                                }
                                            });
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
